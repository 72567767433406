import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingCenter from '../../../Components/LoadingCenter';
import DeleteButton from '../../../Components/DeleteButton';
import DateLabel from '../../../Components/DateLabel';
import NumberColor from '../../../Components/NumberColor';
import BackButtonRouter from '../../../Components/BackButtonRouter';
import ProgramHeader from '../../../Components/ProgramHeader';
import FileManager from '../../../Components/FileManager';
import AuthRender from '../../../Components/AuthRender';

import APIGet from '../../../API/APIGet';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';
import OpenLink from '../../../Helper/OpenLink';
import AdminLockRender from '../../../Helper/AdminLockRender';

import AppConfig from '../../../AppConfig';

import ApproveModal from './ApproveModal';
import Status from './Status';

export default class IncentiveExpenseDetail extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			program: null,
			expense: null,
			isShowApproveModal: false,
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const { programId, expenseCategoryId, expenseId } = this.props.match.params;
			let requestProgram = APIGet('incentive/programs/' + programId);
			let requestExpense = APIGet('incentive/programs/' + programId + '/expenses/' + expenseCategoryId + '/' + expenseId);
			requestProgram = await requestProgram;
			requestExpense = await requestExpense;
			if (APIHelper.handleAPIResponse(this, requestProgram, requestExpense)) {
				const program = requestProgram.data;
				const expense = requestExpense.data;
				console.log('program: ', program);
				console.log('expense: ', expense);
				this.setState({
					isLoading: false,
					program,
					expense,
				});
			} else if (requestProgram.status === 404) {
				toast.warning('ไม่พบโปรแกรมดังกล่าว');
			} else {
				toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	delete = async () => {
		const { programId, expenseCategoryId, expenseId } = this.props.match.params;
		this.setState({
			isLoading: true,
		});

		let requestDelete = APIDelete('incentive/programs/' + programId + '/expenses/' + expenseId);
		requestDelete = await requestDelete;
		if (APIHelper.handleAPIResponse(this, requestDelete)) {
			toast.success('ลบเรียบร้อยแล้ว');
			const { programId } = this.props.match.params;
			this.props.history.replace(AppConfig.appPath + '/programs/incentive/' + programId + '/expenses/' + expenseCategoryId);
		} else if (requestDelete.status === 400) {
			toast.warn('คุณไม่มีสิทธิ์ลบรายการนี้');
			this.setState({
				isLoading: false,
			});
		} else {
			toast.error('ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง');
			this.setState({
				isLoading: false,
			});
		}
	};

	requestApproval = async () => {
		this.setState({
			isLoading: true,
		});
		const { programId, expenseCategoryId, expenseId } = this.props.match.params;
		const request = await APIPut('incentive/programs/' + programId + '/expenses/' + expenseId + '/approval', {
			approvalReason: '',
			approvalStatus: 'Waiting',
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const expense = request.data;
			toast.success('แก้ไขเรียบร้อยแล้ว');
			this.setState({
				isLoading: false,
				expense,
			});
		} else {
			toast.error('เกิดปัญหาในการแก้ไข');
			this.setState({
				isLoading: false,
			});
		}
	};

	requestToReviseAgain = async () => {
		this.setState({
			isLoading: true,
		});
		const { programId, expenseCategoryId, expenseId } = this.props.match.params;
		const request = await APIPut('incentive/programs/' + programId + '/expenses/' + expenseId + '/approval', {
			approvalReason: '',
			approvalStatus: 'Staff Review',
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const expense = request.data;
			toast.success('แก้ไขเรียบร้อยแล้ว');
			this.setState({
				isLoading: false,
				expense,
			});
		} else {
			toast.error('เกิดปัญหาในการแก้ไข');
			this.setState({
				isLoading: false,
			});
		}
	};

	toggleIsPaid = async () => {
		this.setState({
			isLoading: true,
		});
		const { programId, expenseCategoryId, expenseId } = this.props.match.params;
		const request = await APIPut('incentive/programs/' + programId + '/expenses/' + expenseId + '/togglePaid');

		if (APIHelper.handleAPIResponse(this, request)) {
			const expense = request.data;
			toast.success('แก้ไขเรียบร้อยแล้ว');
			this.setState({
				isLoading: false,
				expense,
			});
		} else {
			toast.error('เกิดปัญหาในการแก้ไข');
			this.setState({
				isLoading: false,
			});
		}
	};

	updateApprovalStatus = expense => {
		this.setState({
			expense,
			isShowApproveModal: false,
		});
	};

	onUploadSuccess = expense => {
		toast.success('Upload เรียบร้อยแล้ว');
		this.setState({
			expense,
		});
	};

	onUploadFail = () => {
		toast.error('เกิดปัญหาในการ Upload');
	};

	onDeleteSuccess = expense => {
		toast.success('ลบเรียบร้อยแล้ว');
		this.setState({
			expense,
		});
	};

	onDeleteFail = () => {
		toast.error('เกิดปัญหาในการลบ');
	};

	toggleApproveModal = () => {
		this.setState({
			isShowApproveModal: !this.state.isShowApproveModal,
		});
	};

	renderApprovalStatus = () => {
		const { expense } = this.state;
		if (expense.approvalStatus === 'Staff Review') {
			return <label className="tag is-info">พนักงานตรวจสอบ</label>;
		} else if (expense.approvalStatus === 'Waiting') {
			return <label className="tag is-warning">รอการอนุมัติ</label>;
		} else if (expense.approvalStatus === 'Approved') {
			return <label className="tag is-success">อนุมัติแล้ว</label>;
		} else if (expense.approvalStatus === 'Not Approved') {
			return <label className="tag is-danger">ไม่อนุมัติ</label>;
		}
	};

	isAllowEdited = approvalStatus => {
		if (AuthHelper.hasRight('Admin')) {
			return true;
		}

		if (approvalStatus === 'Approved') {
			return false;
		}

		return true;
	};

	readyToChangeIsPaid = expense => {
		const result = expense.financeStaff && expense.approvalStatus === 'Approved';
		return result;
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		const { program, expense } = this.state;
		const { programId, expenseCategoryId, expenseId } = this.props.match.params;

		return (
			<div className="container">
				<BackButtonRouter link={AppConfig.appPath + '/programs/incentive/' + programId + '/expenses/' + expenseCategoryId} />
				<ProgramHeader program={program} />
				<div className="section">
					<div className="columns">
						<div className="column is-8">
							<h3 className="subtitle is-5">
								รายจ่าย {expense.name}
								{AdminLockRender(
									program.status,
									this.isAllowEdited(expense.approvalStatus) ? (
										<Link
											to={
												AppConfig.appPath +
												'/programs/incentive/' +
												programId +
												'/expenses/' +
												expenseCategoryId +
												'/' +
												expenseId +
												'/edit'
											}
											className="button is-info"
										>
											แก้ไข
										</Link>
									) : (
										<button disabled className="button is-info">
											แก้ไข
										</button>
									),
									<button disabled className="button is-info">
										แก้ไข
									</button>
								)}
								<span> </span>
								<AuthRender roles={['Admin']}>
									<button className="button is-primary" onClick={this.toggleApproveModal}>
										แก้ไขการ Approve
									</button>
								</AuthRender>
								<span> </span>
								{expense.approvalStatus === 'Staff Review' ? (
									<AuthRender roles={['Staff', 'Finance']}>
										<button className="button is-warning" onClick={this.requestApproval}>
											ส่งเรื่องขอ Approve
										</button>
									</AuthRender>
								) : null}
								{/* {expense.approvalStatus === 'Waiting' ? 
                                    (
                                        <AuthRender roles={['Finance']}>
                                            <button className="button" onClick={this.requestToReviseAgain}>
                                                ขอตรวจสอบอีกครั้ง
                                            </button> 
                                        </AuthRender>
                                    )
                                : null} */}
								{/* {!expense.financeStaff ? 
                                    (
                                        <span className="tag is-primary">
                                            ต้องให้ Finance รับทราบและกรอกรายละเอียดก่อนถึงจะสามารถ Approve รายจ่ายได้
                                        </span>
                                    ) 
                                : null} */}
							</h3>
						</div>
						<div className="column is-4" style={{ textAlign: 'right' }}>
							{AdminLockRender(
								program.status,
								this.isAllowEdited(expense.approvalStatus) ? (
									<DeleteButton label="ลบ" warningMessage="ยืนยันการลบ" onClick={this.delete} />
								) : (
									<button disabled className="button is-danger is-outlined">
										ลบ
									</button>
								)
							)}
						</div>
					</div>
					<div className="columns">
						<div className="column is-5">
							<div>
								ราคา:{' '}
								<strong>
									<NumberColor isExpense={true} number={expense.total} /> {expense.currency}
								</strong>
							</div>
							{expense.currency !== 'THB' ? (
								<div>
									ราคา (THB):{' '}
									<strong>
										<NumberColor isExpense={true} number={expense.total * expense.currencyRate} /> THB
									</strong>{' '}
									{expense.currencyRate === 1 ? <label className="tag is-primary">โปรดตรวจสอบค่าเงิน</label> : <span />}
								</div>
							) : (
								<div />
							)}
							<div>
								พนักงาน: <strong>{expense.staff.name}</strong>
							</div>
							<div>
								Supplier: <strong>{expense.supplier.name}</strong>
							</div>
							<div>
								วันที่:{' '}
								<strong>
									<DateLabel date={expense.paymentDate} />
								</strong>
							</div>
							<div>
								สถานะ: {this.renderApprovalStatus()} {!expense.financeStaff ? <span className="tag is-warning">บัญชียังไม่ตรวจสอบ</span> : null}
							</div>
							<div>
								<strong>เหตุผลการอนุมัติ: </strong>
								<br />
								{expense.approvalReason ? expense.approvalReason : '---'}
							</div>
							<div style={{ marginTop: 20 }}>
								<Status
									title={`สถานะ: ${expense.isPaid && this.readyToChangeIsPaid(expense) ? 'จ่ายแล้ว' : 'ค้างจ่าย'}`}
									status={expense.isPaid}
									onClick={() => {
										if (this.readyToChangeIsPaid(expense)) {
											// if there is no finance staff -> shouldn't change the paid status
											this.toggleIsPaid();
										} else if (!expense.financeStaff) {
											toast.warn('บัญชียังไม่ตรวจสอบ ไม่สามารถเปลี่ยนสถานะการจ่ายได้');
										} else if (expense.approvalReason !== 'Approved') {
											toast.warn('ไม่สามารถปรับสถานะได้เนื่องจากรายการยังไม่ได้รับการ Approved');
										}
									}}
								/>
							</div>
						</div>
						<div className="column is-3">
							<div>
								วิธีการ: <strong>{expense.paymentMethod}</strong>
							</div>

							<div className="content">
								<FileManager
									title="Files"
									files={expense.incentiveExpenseFiles}
									api={'incentive/programs/' + programId + '/expenses/' + expenseCategoryId + '/' + expenseId + '/files'}
									onUploadSuccess={this.onUploadSuccess}
									onUploadFail={this.onUploadFail}
									onDeleteSuccess={this.onDeleteSuccess}
									onDeleteFail={this.onDeleteFail}
								/>
							</div>
						</div>
						<div className="column is-4">
							<div className="content">
								<strong>เอกสาร:</strong>
								<ul>
									{expense.pdfIncentiveExpensePaymentVouchersWithDeleted &&
										expense.pdfIncentiveExpensePaymentVouchersWithDeleted.map(doc => (
											<li key={'paymentVouchers' + doc.id}>
												<a onClick={() => OpenLink.incentivePaymentVoucher(doc.id)} target="_blank">
													{doc.documentNumber} {doc.version > 0 ? `revise ${doc.version}` : null}{' '}
												</a>
												{doc.deleted_at && (
													<label className="tag is-warning">
														Inactive - <DateLabel date={doc.deleted_at} />
														{}
													</label>
												)}
											</li>
										))}
								</ul>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column is-12 table-container">
							<table className="table is-hoverable is-fullwidth is-striped is-bordered">
								<thead>
									<tr className="table-header">
										<td width="40%">รายการ</td>
										<td width="20%">ราคาต่อหน่วย</td>
										<td width="20%">จำนวน</td>
										<td width="20%">ราคารวม</td>
									</tr>
								</thead>
								<tbody>
									{expense.incentiveExpenseList.map(row => (
										<tr key={'expenseDetailTable' + row.id}>
											<td>{row.title}</td>
											<td className="number-cell">
												<NumberColor isExpense={true} number={row.pricePerPiece} />
												{expense.currency}
											</td>
											<td className="number-cell">{row.quantity}</td>
											<td className="number-cell">
												<NumberColor number={row.pricePerPiece * row.quantity} isExpense={true} />
												{expense.currency}
											</td>
										</tr>
									))}
									<tr className="table-summary-row">
										<td colSpan="3">รวม (บาท)</td>
										<td className="number-cell">
											<NumberColor number={expense.total * expense.currencyRate} isExpense={true} />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				{this.state.isShowApproveModal ? (
					<ApproveModal expense={expense} close={this.toggleApproveModal} updateApprovalStatus={this.updateApprovalStatus} />
				) : null}
			</div>
		);
	}
}
